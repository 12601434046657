import * as React from 'react'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { useEndlessHolidayProducts } from '@modules/product-package/transactions/create/use-endless-holiday-products'
import { useEndlessHolidayPrice } from '@modules/product-package/transactions/create/use-endless-holiday-price'

export const EndlessHolidayCreateModalSummaryFooter = (): React.ReactNode => {
  const { addedProducts } = useEndlessHolidayProducts()
  const { getProductPrice } = useEndlessHolidayPrice()

  const totalPrice = addedProducts.reduce(
    (price, product) => asDecimal(price).plus(getProductPrice(product)).toNumber(),
    0,
  )

  return (
    <div className="bg-dark-light p-3">
      <div className="text-right text-white fw-semi-bold">
        <span>Wartość oferty:</span>
        <span className="ml-3 font-16">{formatPriceShort(totalPrice)}</span>
      </div>
    </div>
  )
}
