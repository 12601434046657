import * as React from 'react'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetEndlessHolidayInvitationsQuery } from '@api/endless-holiday'
import { EndlessHolidayInvitationsFilters } from '@modules/product-package/invites/filters'
import { EndlessHolidayInvitationsTable } from '@modules/product-package/invites/table'
import { ProductPackageSet } from '@models/products'

const defaultFilters: BaseTableFilters = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
}

interface Props {
  productPackageSets: ProductPackageSet[]
}

export const EndlessHolidayInvitations = ({ productPackageSets }: Props): React.ReactNode => {
  const [filters, setFilters] = React.useState<BaseTableFilters>(defaultFilters)

  const { data: invitations, isLoading } = useQueryWithPaginationResponse<any>(
    useGetEndlessHolidayInvitationsQuery(filters),
  )

  return (
    <div>
      <EndlessHolidayInvitationsFilters
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        productPackageSets={productPackageSets}
      />
      <EndlessHolidayInvitationsTable
        productPackageSets={productPackageSets}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        invitations={invitations}
      />
    </div>
  )
}
