import * as React from 'react'
import { PageTitle } from '@hyper/page-title'
import { useDocumentTitle } from '@helpers/utils'
import { Card, CardBody } from 'reactstrap'
import { useTabs } from '@components/tabs'
import { ProductPackagesList } from '@modules/product-package/transactions'
import { EndlessHolidayInvitations } from '@modules/product-package/invites'
import { EndlessHolidayTransactionActions } from '@modules/product-package/transactions/add-button'
import { EndlessHolidayInvitationAddButton } from '@modules/product-package/invites/add-invitation-button'
import { useParams } from 'react-router-dom'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { ProductPackageKind } from '@modules/product-package/models'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'

type ProductPackageTabs = 'invitations' | 'transactions'

type Params = 'id' | 'kind'

export const ProductPackageView: React.FC = () => {
  const { id, kind: kind_param } = useParams<Params>()
  const kind = kind_param?.replace(/-/g, '_') as any as ProductPackageKind

  const { product_package_sets } = useProductsAppData()
  const productPackageSets = product_package_sets.filter(productPackageSet => productPackageSet.kind === kind)

  const { Tabs, isTabActive, activeTab } = useTabs<ProductPackageTabs>('transactions')

  const activeTabTitle = isTabActive('invitations') ? 'Zaproszenia' : 'Transakcje'

  const pageData = {
    endless_holiday: {
      title: `Wakacje w nieskończoność — ${activeTabTitle}`,
      menu: NavigationPath.ProductPackage.replace(':kind', 'endless-holiday'),
      tabs: [
        { id: 'transactions' as const, name: 'Transakcje' },
        { id: 'invitations' as const, name: 'Zaproszenia' },
      ],
    },
    voucher: {
      title: 'Vouchery świąteczne',
      menu: NavigationPath.ProductPackage.replace(':kind', 'voucher'),
      tabs: [{ id: 'transactions' as const, name: 'Transakcje' }],
    },
  }[kind]

  useMenuActive(pageData.menu)
  useDocumentTitle(pageData.title)

  return (
    <>
      <PageTitle
        title={pageData.title}
        breadCrumbItems={[
          { label: pageData.title, path: '', active: true },
          { label: activeTabTitle, path: '', active: true },
        ]}
      />
      <Card>
        <CardBody>
          <div className="d-flex position-relative">
            <Tabs tabs={pageData.tabs} className="w-100" />
            {activeTab === 'transactions' && <EndlessHolidayTransactionActions productPackageKind={kind} />}
            {activeTab === 'invitations' && <EndlessHolidayInvitationAddButton />}
          </div>

          {isTabActive('transactions') && (
            <ProductPackagesList productPackageKind={kind} id={id} productPackageSets={productPackageSets} />
          )}
          {isTabActive('invitations') && <EndlessHolidayInvitations productPackageSets={productPackageSets} />}
        </CardBody>
      </Card>
    </>
  )
}
