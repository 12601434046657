import { ProductPackageKind } from '@modules/product-package/models'
import { useAppData } from '@components/hooks/use-app-data'

interface Response {
  listUrl: string
}

export const useProductPackages = (kind: ProductPackageKind): Response => {
  const { urls } = useAppData()

  return {
    listUrl: urls.product_packages.product_package_list.replace('replace-me', kind),
  }
}
