import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { ClientSelect } from '@components/client-select'
import { ClientUser } from '@models/clients'
import { useModal } from '@components/modals/use-modal'
import { EndlessHolidayCreateFormInputs } from '@modules/product-package/transactions/create/modal'
import { InvoiceForm } from '@components/invoice-form'
import { ProductPackageCreateFormBaseFields } from '@modules/product-package/common/create-modal-base-fields'
import { ProductPackageKind } from '@modules/product-package/models'

interface Props {
  initialClient: ClientUser | undefined
  productPackageKind: ProductPackageKind
}

export const ProductPackageFormClientData = ({ initialClient, productPackageKind }: Props) => {
  const [client, setClient] = React.useState<ClientUser | undefined>(initialClient)
  const [handleToggleClientDialog] = useModal('ClientDialog', { setClient })

  const { formState, setValue } = useFormContext<EndlessHolidayCreateFormInputs>()

  React.useEffect(() => {
    setValue('client', client)
  }, [client])

  return (
    <div className="">
      <ClientSelect
        label={<span className="text-secondary mt-0 fw-semi-bold">Wyszukaj klienta</span>}
        setClient={setClient}
        client={client}
        handleToggleClientDialog={handleToggleClientDialog}
        error={formState.errors.client}
        colSize={8}
        formGroupClassName="px-2"
      />

      <div className="mx-1">
        <ProductPackageCreateFormBaseFields productPackageKind={productPackageKind} />
      </div>
      <InvoiceForm wrapperClassName="mx-0 px-2" />
    </div>
  )
}
