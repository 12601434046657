import * as React from 'react'
import { Card, CardBody, Label } from 'reactstrap'
import { SubscriptionListFilter, SubscriptionListFilterParams } from '@modules/subscription/list/filter'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getSubscriptions } from '@store/actions/subscription-actions'
import { subscriptionStatusList, SubscriptionType } from '@modules/subscription/models'
import { SubscriptionListTable } from '@modules/subscription/list/table'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { SubscriptionWebSocketHandler } from '@modules/subscription/web-socket-handler'
import { Link, useParams } from 'react-router-dom'
import { useMenuActive } from '@components/hooks/use-menu-active'
import { NavigationPath } from '@models/routes'
import { clearSubscriptions, subscriptionsSelector } from '@store/slices/subscription-slice'
import { SubscriptionDetailsView } from '@modules/subscription/list/details'
import { useTableFilters } from '@components/table/use-table-filters'
import { ColAuto } from '@hyper/col-auto'
import { SubscriptionCreateButton } from '@modules/subscription/create'
import { PageTitleWithSize } from '@hyper/page-title-with-size'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { isProductPackageSubscription } from '@modules/product-package/consts'

const defaultFilters: SubscriptionListFilterParams = {
  created_after: undefined,
  accommodation_type: null,
  kind: [],
  option_kinds: [],
  created_before: undefined,
  seller: undefined,
  source_marketing: undefined,
  status: subscriptionStatusList.filter(row => ['initial', 'confirmed'].includes(row.value)),
  search: '',
  ordering: '-created',
  page: 1,
  page_size: 10,
}

interface Props {
  type: SubscriptionType
}

export const SubscriptionGenericView: React.FC<Props> = ({ type }) => {
  const { id } = useParams<'id'>()

  const allSubscriptions = useAppSelector(subscriptionsSelector)
  const dispatch = useAppDispatch()

  useSubscriptionAppData()
  useMenuActive(NavigationPath.SubscriptionList)
  const { product_name, allow_menage_products } = useSubscriptionTypeStrategyProvider(type)

  const { isLoading, filters, setFilters, updateFiltersRequest } = useTableFilters<SubscriptionListFilterParams>({
    defaultFilters,
    action: async filters => await dispatch(getSubscriptions([filters, type])),
    deps: [type],
  })

  const subscriptions = React.useMemo(
    () =>
      allSubscriptions.filter(row =>
        filters.status?.length ? filters.status?.some(status => status.value === row.status) : true,
      ),
    [allSubscriptions, filters.status, type],
  )

  React.useEffect(
    () => () => {
      dispatch(clearSubscriptions())
    },
    [],
  )

  useDidUpdateEffect(() => {
    updateFiltersRequest(filters)
  }, [type])

  const productUrl =
    (
      {
        GO_HOLIDAY: NavigationPath.SubscriptionGoHolidayProductList,
        WITH_BENEFIT_20: NavigationPath.SubscriptionWithBenefit20ProductList,
        WITH_BENEFIT: NavigationPath.SubscriptionWithBenefitProductList,
        PRODUCT_PACKAGE: NavigationPath.ProductPackage.replace(':kind', 'endless-holiday'),
        PRODUCT_PACKAGE_VOUCHER: NavigationPath.ProductPackage.replace(':kind', 'voucher'),
      } as Record<SubscriptionType, NavigationPath>
    )[type] || ''

  return (
    <>
      <PageTitleWithSize title={product_name} />
      <Card>
        <SubscriptionWebSocketHandler type={type} />
        {id && <SubscriptionDetailsView type={type} id={parseInt(id, 10)} />}

        <CardBody>
          <SubscriptionListFilter defaultFilters={defaultFilters} filters={filters} setFilters={setFilters} type={type}>
            <ColAuto className="ml-auto px-0">
              <Label className="d-block">&nbsp;</Label>
              {allow_menage_products && productUrl && (
                <Link to={productUrl} className="btn btn-light mr-2">
                  {isProductPackageSubscription(type) ? 'Lista voucherów' : 'Lista produktów'}
                </Link>
              )}
              {!['BOOKING_IMPROVEMENT'].includes(type) && !isProductPackageSubscription(type) && (
                <SubscriptionCreateButton type={type} />
              )}
            </ColAuto>
          </SubscriptionListFilter>
          <SubscriptionListTable
            type={type}
            isLoading={isLoading}
            subscriptions={subscriptions}
            filters={filters}
            setFilters={setFilters}
          />
        </CardBody>
      </Card>
    </>
  )
}
