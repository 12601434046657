import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { EndlessHolidayTransactionsTableRow } from '@modules/product-package/transactions/table-row'
import { ProductPackage, ProductPackageAggregation, ProductPackageKind } from '@modules/product-package/models'
import { EndlessHolidayTransactionFormInputs } from '@modules/product-package/transactions/index'
import { EndlessHolidayTransactionsTableFooter } from '@modules/product-package/transactions/table-footer'
import { ProductPackageSet } from '@models/products'

interface Props {
  transactions: ProductPackage[]
  setFilters: (filters: EndlessHolidayTransactionFormInputs) => void
  filters: TableFilters
  isLoading: boolean
  aggregation: ProductPackageAggregation | undefined
  productPackageSets: ProductPackageSet[]
  productPackageKind: ProductPackageKind
}

export const EndlessHolidayTransactionsTable: React.FC<Props> = ({
  setFilters,
  transactions,
  filters,
  isLoading,
  aggregation,
  productPackageSets,
  productPackageKind,
}) => {
  const isEndlessHoliday = productPackageKind === 'endless_holiday'

  const headerValues: TableHeaderValue[] = [
    { title: 'Lp.' },
    { sortField: 'name', title: 'Numer' },
    { title: 'Klient' },
    { title: 'Status' },
    { title: 'Zestaw' },
    { title: 'Kwota brutto (pobyty)' },
    ...(isEndlessHoliday ? [{ title: 'Kwota brutto (kaucja)' }, { title: 'Kwota brutto (VG)' }] : []),
    { title: 'Sprzedawca' },
    { title: 'Źródło' },
    { title: 'Data utworzenia' },
    { title: '' },
  ]

  return (
    <Table
      className="table-hover w-100"
      headerValues={headerValues}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    >
      {transactions.map((transaction: ProductPackage, index: number) => (
        <EndlessHolidayTransactionsTableRow
          key={transaction.id}
          transaction={transaction}
          index={index}
          productPackageSets={productPackageSets}
          productPackageKind={productPackageKind}
        />
      ))}
      {!!transactions.length && aggregation && (
        <EndlessHolidayTransactionsTableFooter aggregation={aggregation} productPackageKind={productPackageKind} />
      )}
    </Table>
  )
}
