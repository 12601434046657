import * as React from 'react'
import { PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { EndlessHolidayProduct } from '@modules/product-package/transactions/create/modal'
import { formatPriceShort } from '@helpers/utils'
import { useEndlessHolidayProducts } from '@modules/product-package/transactions/create/use-endless-holiday-products'
import { ProductPackageKind } from '@modules/product-package/models'

interface Props {
  product: EndlessHolidayProduct
  className?: string
  productPackageKind: ProductPackageKind
}

export const EndlessHolidayFormSummaryProductRow = ({
  product,
  className,
  productPackageKind,
}: Props): React.ReactNode => {
  const { getProduct } = useEndlessHolidayProducts()

  const subscription = getProduct(product.subscription)
  const gastroCard = getProduct(product.gastro_card)

  const clientDateChangeOption = product.options?.find(option => option.context.code === 'single_client_date')

  return (
    <PackageSummarySectionDataRow
      className={className}
      key={product.subscription}
      title="Produkt:"
      content={
        <div className="fw-semi-bold">
          <div className="mb-1">{subscription?.name}</div>
          {productPackageKind === 'endless_holiday' && (
            <>
              <div className="mb-1">
                Gastro voucher: {gastroCard ? formatPriceShort(gastroCard?.price_brutto) : 'Brak'}
              </div>
              <div className="mb-1">Opcja zmiany daty: {clientDateChangeOption?.label}</div>
            </>
          )}
          <div>Ilość: {product.amount}</div>
        </div>
      }
    />
  )
}
