import { SubscriptionKind } from '@modules/subscription/models'
import { UserPermission } from '@models/dashboard'
import { SubscriptionContractPlans } from '@modules/subscription-contract/models'
import { BaseDictionary, BaseUrls } from '@models/base'
import { GenericVoucherKind, GenericVoucherStrategy } from '@modules/generic-vouchers/models'

export interface SubscriptionKindVersion {
  id: number
  display_name: string
  kind: SubscriptionKind
  kind_display: string
}

export type GastroProductAssignType = 'booking' | 'client'

export const AvailableGastroProductKind = [
  'gastro_coupon',
  'gastro_card',
  'gastro_card_boost',
  'gastro_card_boost_wholesale',
  'gastro_card_2024',
  'gastro_card_2025',
  'gastro_card_2024_wholesale',
  'gastro_card_2025_wholesale',
  'gastro_card_compensation',
  'gastro_card_2026',
  'gastro_card_2026_october',
  'gastro_card_2026_november',
  'gastro_card_2027',
  'gastro_card_dynamic',
  'gastro_card_black_week_2024',
  'gastro_card_christmas_2024',
] as const

export type GastroProductKind = (typeof AvailableGastroProductKind)[number]
type GenericVoucherStrategies = {
  [key in GenericVoucherKind]: GenericVoucherStrategy
}

type GastroProducts = {
  [key in GastroProductKind]: ProductsAppDataGastroProduct
}

export interface ProductsAppDataGastroProduct {
  minimal_value: string
  product_name: string
  wallet_kind: 'fiscalization' | 'gift'
  assign_type: GastroProductAssignType
  can_define_scopes: boolean
  kind: GastroProductKind
  notifications: [string, string][]
  allow_pay_by_cash: boolean
  can_set_source_marketing: boolean
  crud_permissions: {
    list_ecommerce: UserPermission
    can_change_declared_sell_price: UserPermission
    notification: UserPermission
    cancel: UserPermission
    status: UserPermission
    retrieve: UserPermission
    list: UserPermission
    create: UserPermission
    partial_update: UserPermission
    update: UserPermission
    destroy: UserPermission
  }
}

export interface GastroProductInfo {
  promotion_multiplier: string
  tags: string[]
  active_after: string
  expire_after: string
}

export interface GastroGenericProductTag {
  id: string
  name: string
}

export type ProductPackageSetKind = 'voucher' | 'endless_holiday'

export interface ProductPackageSet {
  id: string
  name: string
  status: 'available' | 'unavailable'
  urls: BaseUrls
  kind: ProductPackageSetKind
}

export interface ProductsAppData {
  client_option_purchase_mails: [string, string][]
  subscription_kind: [SubscriptionKind, string][]
  status: 'unknown' | 'ready' | 'loading'
  gastro_products: GastroProducts
  generic_voucher_strategies: GenericVoucherStrategies
  gastro_card_tags: GastroGenericProductTag[]
  subscription_kind_versions: SubscriptionKindVersion[]
  subscription_contract_plans: SubscriptionContractPlans
  gastro_card_reason_for_creation: BaseDictionary[]
  product_package_sets: ProductPackageSet[]
}
