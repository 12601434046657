import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  EndlessHolidayCreateFormInputs,
  EndlessHolidayProduct,
} from '@modules/product-package/transactions/create/modal'
import { useGetProductPackageSetDetailsQuery } from '@api/endless-holiday'
import { TypedQueryResult } from '@api/base'
import { ProductPackageSetDetails, ProductPackageSetProduct } from '@modules/product-package/models'
import { getById } from '@helpers/utils'

interface Response {
  getProduct: (id: string | null) => ProductPackageSetProduct | undefined
  addedProducts: EndlessHolidayProduct[]
  packageData: ProductPackageSetDetails | undefined
}

export const useEndlessHolidayProducts = (): Response => {
  const { product_package_sets } = useProductsAppData()
  const { control } = useFormContext<EndlessHolidayCreateFormInputs>()
  const [addedProducts, product_package_set] = useWatch({ control, name: ['products', 'product_package_set'] })

  const productPackageSet = getById(product_package_sets, product_package_set?.value)

  const { currentData: packageData } = useGetProductPackageSetDetailsQuery(productPackageSet?.urls.details, {
    skip: !productPackageSet,
  }) as TypedQueryResult<ProductPackageSetDetails>

  const getProduct = (id: string | null) => (packageData?.products || []).find(el => id && el.id === parseInt(id, 10))

  return {
    getProduct,
    addedProducts,
    packageData,
  }
}
