import * as React from 'react'
import { EndlessHolidayTransactionsFilters } from '@modules/product-package/transactions/filters'
import { EndlessHolidayTransactionsTable } from '@modules/product-package/transactions/table'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetEndlessHolidayTransactionDetailsQuery, useGetProductPackagesQuery } from '@api/endless-holiday'
import { CustomReactSelectOption } from '@components/custom-react-select'
import {
  ProductPackage,
  ProductPackageAggregation,
  ProductPackageDetails,
  ProductPackageKind,
} from '@modules/product-package/models'
import { EndlessHolidayViewParamsTransactionDetails } from '@modules/product-package/transactions/view-details-modal'
import { TypedQueryResult } from '@api/base'
import { ProductPackageSet } from '@models/products'
import { useProductPackages } from '@modules/product-package/use-product-packages'

export interface EndlessHolidayTransactionFormInputs extends BaseTableFilters {
  status: CustomReactSelectOption[]
  source_marketing: CustomReactSelectOption | null
  accommodation_type: CustomReactSelectOption | null
  kind: CustomReactSelectOption | null
  created_before: Date | null
  created_after: Date | null
  payment_before: Date | null
  payment_after: Date | null
}

const defaultFilters: EndlessHolidayTransactionFormInputs = {
  ordering: '-created',
  page: 1,
  page_size: 10,
  search: '',
  status: [],
  source_marketing: null,
  accommodation_type: null,
  kind: null,
  created_before: null,
  created_after: null,
  payment_before: null,
  payment_after: null,
}

interface Props {
  id: string | undefined
  productPackageSets: ProductPackageSet[]
  productPackageKind: ProductPackageKind
}

export const ProductPackagesList = ({ id, productPackageSets, productPackageKind }: Props): React.ReactNode => {
  const { listUrl } = useProductPackages(productPackageKind)
  const [filters, setFilters] = React.useState<EndlessHolidayTransactionFormInputs>(defaultFilters)

  const { data, aggregation, isLoading } = useQueryWithPaginationResponse<ProductPackage, ProductPackageAggregation>(
    useGetProductPackagesQuery({ ...filters, productPackageKind: productPackageKind }),
    { withAggregation: true },
  )

  const { data: productDetails } = useGetEndlessHolidayTransactionDetailsQuery(`${listUrl}${id}/`, {
    skip: !id,
  }) as TypedQueryResult<ProductPackageDetails>

  return (
    <div>
      {productDetails && <EndlessHolidayViewParamsTransactionDetails details={productDetails} />}
      <EndlessHolidayTransactionsFilters
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        productPackageSets={productPackageSets}
      />
      <EndlessHolidayTransactionsTable
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        transactions={data}
        aggregation={aggregation}
        productPackageSets={productPackageSets}
        productPackageKind={productPackageKind}
      />
    </div>
  )
}
