import * as React from 'react'
import { SubscriptionCode, SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionDetailsCodeNameEdit } from '@modules/subscription/details/code-name-edit'
import { useToggle } from '@components/hooks/use-toggle'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  subscriptionCode: SubscriptionCode
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsCodeName: React.FC<Props> = ({ subscriptionDetails, subscriptionCode }) => {
  const [isEditFormVisible, toggleIsEditFormVisible] = useToggle()

  const canSetName =
    subscriptionDetails.type === 'PRODUCT_PACKAGE_VOUCHER' &&
    subscriptionDetails.status === 'confirmed' &&
    subscriptionDetails.subscription_code_name_kind === 'nameless' &&
    !subscriptionCode.is_used

  if (!canSetName) {
    return subscriptionCode.name && <div className="mt-1 font-11">{subscriptionCode.name}</div>
  }

  return isEditFormVisible ? (
    <SubscriptionDetailsCodeNameEdit toggleIsEditable={toggleIsEditFormVisible} subscriptionCode={subscriptionCode} />
  ) : subscriptionCode.name ? (
    <span title="Kliknij dwukrotnie aby zmienić" className="cursor-pointer" onDoubleClick={toggleIsEditFormVisible}>
      {subscriptionCode.name}
    </span>
  ) : (
    <IconWithText icon="uil-pen font-10" text="ustaw" textClass="font-12" onClick={toggleIsEditFormVisible} />
  )
}
