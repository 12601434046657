import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption } from '@helpers/utils'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useProductsAppData } from '@modules/products/hooks/use-products-app-data'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ProductPackageKind } from '@modules/product-package/models'
import { ProductPackageSet } from '@models/products'

interface Props {
  productPackageKind: ProductPackageKind
}

export const ProductPackageCreateFormBaseFields = ({ productPackageKind }: Props): React.ReactNode => {
  const { product_package_sets } = useProductsAppData()

  const availableSets: CustomReactSelectOption[] = product_package_sets.reduce(
    (sets: CustomReactSelectOption[], set: ProductPackageSet) =>
      set.kind === productPackageKind
        ? [...sets, createSelectOption(set.name, set.id, set.status === 'unavailable')]
        : sets,
    [],
  )

  const sortedSets = availableSets.toSorted((a, b) => (a.disabled ? 1 : b.disabled ? -1 : 0))

  const sellers = useGroupedSellers([])
  const sources = useGroupedSources({ withAllOption: false })

  return (
    <div className="mx-n1">
      <div className="dropdown-divider mb-0 mt-3" />
      <div className="bg-grey py-3 px-3">
        <div className="row align-items-center">
          <strong className="col-3">Wybierz zestaw</strong>
          <div className="col-9">
            <FormSelect
              options={sortedSets}
              selectProps={{ isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled }}
              name="product_package_set"
              formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
            />
          </div>
        </div>

        <div className="row align-items-center mt-2">
          <strong className="col-3">Sprzedawca</strong>
          <div className="col-9">
            <FormSelect
              selectProps={{ isClearable: true }}
              options={sellers}
              name="seller"
              formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
            />
          </div>
        </div>

        <div className="row align-items-center mt-2">
          <strong className="col-3">Źródło marketingowe</strong>
          <div className="col-9">
            <FormSelect
              selectProps={{ isClearable: true }}
              options={sources}
              name="source_marketing"
              formPlainProps={{ colSize: 9, colClassName: 'px-0', formGroupClassName: 'mb-0' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
