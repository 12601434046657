import * as React from 'react'
import { Subscription } from '@modules/subscription/models'
import Table from '@components/table/table'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { asDecimal, formatPrice, getById } from '@helpers/utils'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { TableUserRow } from '@components/table-user-row'
import { TableSellerRow } from '@components/table-seller-row'
import { useSubscriptionOptions } from '@modules/subscription/hooks/use-subscription-options'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'

interface Props {
  subscription: Subscription
}

export const ClientDetailsModalSubscriptionsRow: React.FC<Props> = ({ subscription }) => {
  const { sources } = useReportsAppData()

  const { products } = useSubscriptionAppData()

  const productName = React.useMemo(
    () => getById(products, subscription.product_id)?.name || '',
    [products, subscription],
  )

  const source = React.useMemo(
    () => getById(sources, subscription.source_marketing_id)?.name || '',
    [sources, subscription],
  )

  const isPaymentRequired = React.useMemo(
    () => asDecimal(subscription.required_payment).gt(asDecimal(0)),
    [subscription.required_payment],
  )

  const { hasOption } = useSubscriptionOptions(subscription)

  const url = {
    DEFAULT: NavigationPath.SubscriptionListWithParams,
    WHOLESALE: NavigationPath.PackageWholesalePackagesWithPrams,
    WITH_BENEFIT: NavigationPath.SubscriptionWithBenefitListWithParams,
    GO_HOLIDAY: NavigationPath.SubscriptionGoHolidayListWithParams,
    WITH_BENEFIT_20: NavigationPath.SubscriptionWithBenefit20ListWithParams,
    CAFETERIA: NavigationPath.SubscriptionCafeteriaListWithParams,
    PRODUCT_PACKAGE: NavigationPath.ProductPackageSubscriptionsListWithParams.replace(':kind', 'endless_holiday'),
    PRODUCT_PACKAGE_VOUCHER: NavigationPath.ProductPackageSubscriptionsListWithParams.replace(':kind', 'voucher'),
  }[subscription.type]

  return (
    <Table.Row key={subscription.id}>
      <Table.Cell>
        <Link to={url.replaceParam(':id', subscription.id)} className="table__link-row">
          {subscription.number}
        </Link>
        <span className="d-block font-10">
          Użyto{' '}
          <strong>
            {subscription.used_codes} z{' '}
            {subscription.local_mountains + subscription.local_sea + subscription.local_universal}
          </strong>
        </span>
      </Table.Cell>
      <Table.Cell>{subscription.product?.name ?? productName}</Table.Cell>
      <Table.Cell>
        {hasOption('single_client_date') && (
          <BadgeWithIcon icon="mdi-check mdi" title="Jednorazowa zmiana daty" variant="success" />
        )}
        {hasOption('client_data') && <BadgeWithIcon icon="mdi-check mdi" title="Zmiana danych" variant="success" />}
        {hasOption('localization') && (
          <span className={classNames('badge', 'badge-success-lighten')}>
            <i className={classNames('mdi mdi-check', 'mr-1')} />
            Konwersja kodów
          </span>
        )}
        {hasOption('zoo_borysew') && (
          <BadgeWithIcon icon="mdi-check mdi" title="Bilety Zoo Borysew" variant="success" />
        )}
      </Table.Cell>
      <Table.Cell>
        <StatusBadge element={subscription} />
      </Table.Cell>
      <Table.Cell
        className={classNames('text-strong', {
          'text-danger': isPaymentRequired,
        })}
      >
        {formatPrice(subscription.required_payment)}
      </Table.Cell>
      <Table.Cell>{source}</Table.Cell>
      <Table.Cell>
        <TableSellerRow sellerId={subscription.seller_id} defaultValue={subscription.seller} className="d-block" />
      </Table.Cell>
      <Table.Cell>
        <TableUserRow userId={subscription.created_by_id} defaultValue={subscription.created_by} className="d-block" />

        <small className="font-10">{toDefaultDateTimeFormat(subscription.created)}</small>
      </Table.Cell>
    </Table.Row>
  )
}
