import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayCreateFormInputs } from '@modules/product-package/transactions/create/modal'
import { useEndlessHolidayPrice } from '@modules/product-package/transactions/create/use-endless-holiday-price'

interface Props {
  index: number
}

export const EndlessHolidayFormProductDataPrice = ({ index }: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayCreateFormInputs>()
  const product = useWatch({ control, name: `products.${index}` })

  const { getProductPrice } = useEndlessHolidayPrice()

  const price = getProductPrice(product)

  return (
    <div className="text-right">
      <div className="bg-white-smoke-dark p-2 d-inline-block font-weight-bold font-12 ml-auto mb-n2 mr-n2">
        <span className="pr-5">Cena produktu:</span>
        {formatPriceShort(price)}
      </div>
    </div>
  )
}
