import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { useCreateEndlessHolidayTransactionMutation } from '@api/endless-holiday'
import { EndlessHolidayCreateFormInputs } from '@modules/product-package/transactions/create/modal'
import { createInvoiceData } from '@components/products/product-invoice-card'
import { useModal } from '@components/modals/use-modal'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { ProductPackageKind } from '@modules/product-package/models'
import { useProductPackages } from '@modules/product-package/use-product-packages'
import { ReceptionBookingCheckStep } from '@modules/reception/common/reception-booking-check-steps'

interface Response {
  create: (onCreate: () => void) => (data: EndlessHolidayCreateFormInputs) => Promise<void>
  isLoading: boolean
}

export const useEndlessHolidayCreate = (
  steps: ReceptionBookingCheckStep[],
  setStep: (step: number) => void,
  setError,
  productPackageKind: ProductPackageKind,
): Response => {
  const { listUrl } = useProductPackages(productPackageKind)

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const [showDetailsModal] = useModal('EndlessHolidayDetailsModal')

  const [createEndlessHolidayTransaction, { isLoading, error, isError }] = useCreateEndlessHolidayTransactionMutation()
  useHandleRtkQueryError(error, isError, setError, { setStep, steps })

  const create = (onCreate: () => void) => async (data: EndlessHolidayCreateFormInputs) => {
    const gastroProducts = data.products.reduce((products, product) => {
      if (!product.gastro_card) return products
      return [
        ...products,
        { product: product.gastro_card, related_product: product.subscription, amount: product.amount },
      ]
    }, [])

    const product = await createEndlessHolidayTransaction({
      url: listUrl,
      payload: {
        client: data.client?.id,
        product_package_set: data.product_package_set?.value,
        products: [
          ...data.products.map(product => ({
            product: product.subscription,
            amount: product.amount,
            related_product: null,
            single_client_date: product.options.some(
              option => option.value === 'yes' && option.context.code === 'single_client_date',
            ),
          })),
          ...gastroProducts,
        ],
        seller: data.seller?.value,
        source_marketing: data.source_marketing?.value,
        ...(!!data.invoice_nip && { ...createInvoiceData(data), invoice: true }),
      },
    }).unwrap()

    showConfirmationPackageCreation(() => showDetailsModal(null, { product }))

    onCreate()
  }

  return {
    create,
    isLoading,
  }
}
