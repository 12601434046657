import * as React from 'react'
import { ClientDetails } from '@models/clients'
import { Card, CardBody, Col } from 'reactstrap'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { Subscription, subscriptionStatusList } from '@modules/subscription/models'
import { ClientDetailsModalSubscriptionsRow } from '@modules/crm/clients/details-modal/subscriptions/subscriptions-row'
import { useSortedRows } from '@components/table/use-sorted-rows'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { ClientDetailsModalSubscriptionsTableFilters } from '@modules/crm/clients/details-modal/subscriptions/filters'
import { filterClientDetailsProductByStatus } from '@modules/crm/clients/details-modal/utils'

const TypesToShow = [
  'DEFAULT',
  'WHOLESALE',
  'WITH_BENEFIT',
  'WITH_BENEFIT_20',
  'CAFETERIA',
  'GO_HOLIDAY',
  'PRODUCT_PACKAGE',
  'PRODUCT_PACKAGE_VOUCHER',
]

export interface ClientDetailsModalSubscriptionsFilters extends TableFilters {
  statuses: CustomReactSelectOption[]
}

const defaultFilters = {
  search: '',
  ordering: 'number',
  page: 1,
  page_size: 9999,
  statuses: subscriptionStatusList.filter(status => ['confirmed', 'initial'].includes(status.value)),
}

const headerValues: TableHeaderValue<Subscription>[] = [
  { sortField: 'number', title: 'Nr pakietu', className: 'text-nowrap' },
  { sortField: 'type_value', title: 'Produkt' },
  { title: 'Opcje' },
  { sortField: 'status_display', title: 'Status' },
  { sortField: 'total_price_brutto_without_used_booking', title: 'Do zapłaty', className: 'text-nowrap' },
  { sortField: 'source_marketing_id', title: 'Żródło' },
  { sortField: 'seller', title: 'Sprzedawca' },
  { sortField: 'created_by', title: 'Utworzony przez' },
]

interface Props {
  client: ClientDetails
}

export const ClientDetailsModalSubscriptions: React.FC<Props> = ({ client }) => {
  const [filters, setFilters] = React.useState<ClientDetailsModalSubscriptionsFilters>(defaultFilters)

  const sortedSubscriptions = useSortedRows<Subscription>(client.subscriptions, filters.ordering)

  const filteredSubscriptions = React.useMemo(
    () =>
      filterClientDetailsProductByStatus(
        [...sortedSubscriptions].filter(subscription => TypesToShow.includes(subscription.type)),
        filters.statuses,
      ),
    [sortedSubscriptions, filters.statuses],
  )

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <h4 className="text-secondary mb-2">Pakiety</h4>
          <ClientDetailsModalSubscriptionsTableFilters
            filters={filters}
            onFiltersChange={setFilters}
            defaultFilters={defaultFilters}
            subscriptionStatusOptions={subscriptionStatusList}
            client={client}
          />
          <Table
            className="table-hover w-100"
            headerValues={headerValues}
            showPagination={false}
            isLoading={false}
            emptyText="Brak pakietów prywatnych"
            filters={filters}
            setFilters={setFilters}
          >
            {filteredSubscriptions.map(subscription => (
              <ClientDetailsModalSubscriptionsRow key={subscription.id} subscription={subscription} />
            ))}
          </Table>
        </CardBody>
      </Card>
    </Col>
  )
}
