import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { useFormRequest } from '@components/hooks/use-api-request'
import { commonObjectPost } from '@store/actions/generic-actions'
import { Form } from '@hyper/forms/form'
import { FormInput } from '@hyper/forms'
import { InlineConfirmReject } from '@components/inline-confirm-reject'
import { SubscriptionCode } from '@modules/subscription/models'
import { setSubscriptionDetails } from '@store/slices/subscription-slice'

interface Props {
  toggleIsEditable: () => void
  subscriptionCode: SubscriptionCode
}

interface FormInputs {
  name: string
}

export const SubscriptionDetailsCodeNameEdit = ({ toggleIsEditable, subscriptionCode }: Props): React.ReactNode => {
  const methods = useForm<FormInputs>({ defaultValues: { name: subscriptionCode.name } })

  const { addSuccessNotification } = useNotificationHook()
  const dispatch = useAppDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(setSubscriptionDetails(await commonObjectPost(subscriptionCode.urls.activate, payload)))
    addSuccessNotification(`Kod ${subscriptionCode.code} został zmieniony!`)
    toggleIsEditable()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit} className="d-flex">
      <FormInput
        name="name"
        formGroupClassName="mb-0"
        colClassName="px-0"
        colSize="col-auto"
        inputClassName="rounded-right-0"
      />
      <InlineConfirmReject
        onReject={toggleIsEditable}
        onConfirm={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      />
    </Form>
  )
}
