import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductPackageDetails } from '@modules/product-package/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { ProductCardTitle } from '@components/products/product-card-title'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { formatPrice, getById } from '@helpers/utils'
import { ProductSalePageButton } from '@components/products/product-action-button'
import { useReportsAppData } from '@modules/reports/hooks/use-reports-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  productDetails: ProductPackageDetails
}

export const EndlessHolidayDetailsBaseData = React.forwardRef(
  ({ productDetails }: Props, ref: React.RefObject<HTMLElement>) => {
    const { sources } = useReportsAppData()
    const user = useAuthenticatedUser()

    const source = getById(sources, productDetails.source_marketing)

    return (
      <div className="col-6">
        <Card innerRef={ref}>
          <CardBody>
            <ProductCardTitle
              title="Szczegóły"
              isEditAllowed={user.is_superuser}
              adminUrl={productDetails.urls.admin}
            />
            <table className="table table-sm mb-0">
              <tbody>
                <SubscriptionDetailsInformationRow name="Status">
                  <StatusBadge element={productDetails} />
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Numer">
                  {productDetails.number}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Zestaw">
                  {productDetails.product_package_set.name}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Kwota">
                  {formatPrice(productDetails.price_brutto)}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Kwota brutto (pobyty)">
                  {formatPrice(0)}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Kwota brutto (GV)">
                  {formatPrice(0)}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Sprzedawca">
                  {productDetails.seller?.name ?? 'Brak'}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Źródło">
                  {source?.name ?? 'Brak'}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Data utworzenia">
                  {toDefaultDateFormat(productDetails.created)}
                </SubscriptionDetailsInformationRow>
                <SubscriptionDetailsInformationRow name="Data auto anulowania">
                  {toDefaultDateFormat(productDetails.auto_cancel_after_date)}
                </SubscriptionDetailsInformationRow>
              </tbody>
            </table>
            <ProductSalePageButton saleUrl={productDetails.urls.sale} className="ml-auto d-block mt-2" />
          </CardBody>
        </Card>
      </div>
    )
  },
)
